import React from "react";
import { Col, Grid, Row } from "rsuite";
import { StaticImage } from "gatsby-plugin-image";
import { isMobile } from "react-device-detect";

export default function HeaderIndexArts({ title, marked, description }) {
  return (
    <div style={{ position: "relative" }} className="header-gradient">
      <StaticImage
        src="../../images/internal/background2.jpeg"
        alt="Logo Trombosestichting Nederland"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          zIndex: "-2",
        }}
        className="header-image"
      />
      <Grid>
        <Row
          style={{
            padding: isMobile ? "40px 20px" : "80px 0px",
          }}
        >
          <Col md={18}>
            <h1
              style={{
                color: "#fff",
                lineHeight: "1.5",
                fontSize: isMobile ? "2.2em" : "2.8em",
              }}
            >
              {title}
            </h1>
            <p style={{ fontSize: "18px" }}>{description}</p>
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
