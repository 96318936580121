import React from "react";
import { navigate } from "gatsby";
import {
  Button,
  Panel,
  Col,
  FlexboxGrid,
} from "rsuite";
import SectionWrapper from "../section/sectionWrapper";

export default function MapIndexPage() {
  return (
    <>
      <SectionWrapper background padded>
        <FlexboxGrid
          style={{
            alignItems: "stretch",
            width: "1120px",
            maxWidth: "100%",
            margin: "0 auto",
          }}
        >
          <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
            <Panel shaded style={{ backgroundColor: "white" }}>
              <h4 style={{ color: "#000", marginBottom: "10px" }}>
                Organisatie vinden
              </h4>
              <p
                style={{
                  color: "#000",
                  lineHeight: "1.8",
                  fontSize: "16px",
                }}
              >
                Bekijk op de landkaart de kennis- en expertisecentra die zich
                bezighouden met antistollingszorg, en wie de regionale en/of
                lokale aanspreekpunten zijn.
              </p>
              <Button
                appearance="primary"
                size="md"
                onClick={() => navigate("/professional/organisatie-vinden/")}
                style={{ marginTop: "20px", backgroundColor: "#000" }}
              >
                Alle centra bekijken
              </Button>
            </Panel>
          </Col>
          <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
            <Panel
              shaded
              style={{
                backgroundColor: "#fff",
                height: "100%",
              }}
            >
              <FlexboxGrid
                justify="space-between"
                style={{ flexFlow: "column wrap", height: "100%" }}
              >
                <div style={{ width: "100%" }}>
                  <h4
                    style={{
                      color: "#000",
                      fontSize: "22px",
                      marginBottom: "10px",
                    }}
                  >
                    Richtlijnen
                  </h4>
                  <p
                    style={{
                      color: "#000",
                      lineHeight: "1.8",
                      fontSize: "16px",
                    }}
                  >
                    Je kunt rondkijken in de beschikbare richtlijnen.
                  </p>
                </div>
                <div style={{ width: "100%" }}>
                  <Button
                    appearance="primary"
                    size="md"
                    onClick={() => navigate("/professional/richtlijnen/")}
                    style={{ marginTop: "20px", backgroundColor: "#000" }}
                  >
                    Alles bekijken
                  </Button>
                </div>
              </FlexboxGrid>
            </Panel>
          </Col>
          <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
            <Panel
              shaded
              style={{
                backgroundColor: "#fff",
                height: "100%",
              }}
            >
              <FlexboxGrid
                justify="space-between"
                style={{ flexFlow: "column wrap", height: "100%" }}
              >
                <div>
                  <h4
                    style={{
                      color: "#000",
                      fontSize: "22px",
                      marginBottom: "10px",
                    }}
                  >
                    Protocollen
                  </h4>
                  <p
                    style={{
                      color: "#000",
                      lineHeight: "1.8",
                      fontSize: "16px",
                    }}
                  >
                    Je kunt rondkijken en direct zoeken in de beschikbare
                    regionale protocollen.
                  </p>
                </div>
                <div style={{ width: "100%" }}>
                  <Button
                    appearance="primary"
                    size="md"
                    onClick={() => navigate("/professional/protocollen/")}
                    style={{ marginTop: "20px", backgroundColor: "#000" }}
                  >
                    Alles bekijken
                  </Button>
                </div>
              </FlexboxGrid>
            </Panel>
          </Col>
          <Col xs={12} md={6} style={{ marginBottom: "10px" }}>
            <Panel
              shaded
              style={{
                backgroundColor: "#fff",
                height: "100%",
              }}
            >
              <FlexboxGrid
                justify="space-between"
                style={{ flexFlow: "column wrap", height: "100%" }}
              >
                <div>
                  <h4
                    style={{
                      color: "#000",
                      fontSize: "22px",
                      marginBottom: "10px",
                    }}
                  >
                    E-learning
                  </h4>
                  <p
                    style={{
                      color: "#000",
                      lineHeight: "1.8",
                      fontSize: "16px",
                    }}
                  >
                    Je kunt rondkijken in de beschikbare e-learnings.
                  </p>
                </div>
                <div style={{ width: "100%" }}>
                  <Button
                    appearance="primary"
                    size="md"
                    onClick={() => navigate("/professional/e-learning/")}
                    style={{ marginTop: "20px", backgroundColor: "#000" }}
                  >
                    Alles bekijken
                  </Button>
                </div>
              </FlexboxGrid>
            </Panel>
          </Col>
        </FlexboxGrid>
      </SectionWrapper>
    </>
  );
}
