import * as React from "react";
import {
  Grid,
  Row,
  Col,
  IconButton,
  Panel,
  Carousel,
  List,
} from "rsuite";
import { navigate, Link } from "gatsby";
import LayoutMain from "components/layout/main";
import SectionWrapper from "components/section/sectionWrapper";
import MapIndexPage from "components/map/mapIndexPage";
import SourcesOverviewIndexPage from "components/section/sourcesOverviewIndexPage";
import HeaderIndexArts from "../../components/section/headerIndexArts";
import { BrowserView, isMobile } from "react-device-detect";
import { StaticImage } from "gatsby-plugin-image";
import { isLoggedIn } from "../../components/services/auth";
import { Icon } from "@rsuite/icons";
import { BiArrowToRight, BiLinkExternal } from "react-icons/bi";

const IndexPage = () => {
  return (
    <LayoutMain>
      <HeaderIndexArts title="Het Kennisplatform bevat informatie over lokale ziekenhuisexperts, kennis, instrumenten en initiatieven op het gebied van antistolling" />
      <MapIndexPage />
      {!isLoggedIn() && (
        <SectionWrapper padded>
          <Grid>
            <Row style={{ marginBottom: "0px" }}>
              <Col xs={20} md={16}>
                <h3 style={{ marginBottom: "20px", color: "#000" }}>
                  Over het Kennisplatform
                </h3>
                <p
                  style={{
                    lineHeight: "2",
                    fontSize: "18px",
                    width: "95%",
                    color: "#000",
                  }}
                >
                  Met dit Kennisplatform willen we uitwisseling van kennis op
                  alle terreinen van antistollingszorg bevorderen: ‘Elke
                  patiënt, arts, tandarts of verpleegkundige weet de weg te
                  vinden naar goede expertise over antistolling’. Binnen het
                  Kennisplatform is het volgende te vinden:
                  <ul style={{ paddingInlineStart: "15px", marginTop: "15px" }}>
                    <li>
                      experts op het gebied van antistolling - en met hun
                      bereikbaarheid in de verschillende ziekenhuizen in
                      Nederland
                    </li>
                    <li>
                      regionale samenwerkingsverbanden, expertisecentra en
                      trombosediensten
                    </li>
                    <li>lokale diagnostiek en behandelprotocollen</li>
                    <li>richtlijnen en andere kennisinstrumenten</li>
                    <li>e-learnings</li>
                  </ul>
                </p>
                <IconButton
                  icon={<Icon as={BiArrowToRight} size="1.5em" />}
                  appearance="primary"
                  size="md"
                  style={{ marginTop: "20px" }}
                  onClick={() => navigate("/professional/over-het-kennisplatform")}
                >
                  Verder lezen
                </IconButton>
              </Col>
              <BrowserView>
                <Col md={8} style={{ marginTop: "70px" }}>
                  <StaticImage
                    src="../../images/internal/background3.jpeg"
                    alt="Afbeelding"
                  />
                </Col>
              </BrowserView>
            </Row>
          </Grid>
        </SectionWrapper>
      )}
      <div
        style={{ backgroundColor: isLoggedIn() ? "transparent" : "#F6F4F5" }}
      >
        <SectionWrapper padded>
          <Grid>
            <Row>
              <Col xs={4} style={{ position: "relative" }}>
                <h4
                  style={{
                    fontSize: isMobile ? "40px" : "76px",
                    transform: "rotate(-90deg)",
                    position: "absolute",
                    top: isMobile ? "62px" : "152px",
                    left: isMobile ? "-62px" : "-115px",
                    color: "#000",
                  }}
                >
                  Uitgelicht
                </h4>
              </Col>
              <Carousel autoplay autoplayInterval={8000} shape='bar'>
                <Col
                  xs={20}
                  style={{
                    backgroundColor: "#fff",
                    padding: "40px",
                    height: "100%",
                    border: "1px solid black",
                    borderRadius: "4px",
                  }}
                >
                  <h4 style={{ color: "#000" }}>Artikelen</h4>
                  <h2 style={{ color: "#000" }}>Antistollingszorg in Nederland steeds veiliger</h2>
                  <p
                    style={{

                      fontSize: "1.1em",
                      color: "#000",
                      lineHeight: "1.8",
                      width: "80%",
                      marginTop: "10px",
                    }}
                  >
                    In een artikel op Skipr wordt nader toegelicht dat de antistollingszorg in Nederland steeds veiliger wordt, maar dat er nog voldoende verbeterpunten zijn. Dat concludeert Marco Moesker (Nivel) in zijn proefschrift waarop hij onlangs promoveerde aan de Vrije Universiteit van Amsterdam.
                  </p>
                  <IconButton
                    icon={<Icon as={BiArrowToRight} size="1.5em" />}
                    size="md"
                    style={{ marginTop: "20px" }}
                    appearance="primary"
                    onClick={() =>
                      navigate(
                        "/professional/uitgelicht/artikelen/antistollingszorg-in-nederland-steeds-veiliger/"
                      )
                    }
                  >
                    Verder lezen
                  </IconButton>
                </Col>
                <Col
                  xs={20}
                  style={{
                    backgroundColor: "#d72d22",
                    padding: "40px",
                    height: "100%",
                  }}
                >
                  <h4 style={{ color: "#ebd247" }}>
                    Uitgelichte bronnen
                  </h4>
                  <h3
                    style={{
                      color: "white",
                      fontSize: isMobile ? "16px" : "22px",
                      lineHeight: isMobile ? "1.4" : "initial",
                    }}
                  >
                    De Landelijk Transmurale Afspraak (LTA)
                    <br />
                    Antistollingszorg
                  </h3>
                  <p
                    style={{

                      fontSize: "1.1em",
                      color: "white",
                      lineHeight: "1.8",
                      width: "80%",
                      marginTop: "10px",
                    }}
                  >
                    De landelijke transmurale afspraak (LTA) antistollingszorg
                    is een praktische leidraad met informatie over het
                    voorschrijven en de follow-up van antitrombotica,
                    periprocedureel beleid en het beleid bij bloedingen. De LTA-antistollingszorg is bedoeld voor artsen, tandartsen of
                    verpleegkundigen die te maken hebben met patiënten die
                    antitrombotica voorgeschreven krijgen.
                  </p>
                  <IconButton
                    icon={<Icon as={BiArrowToRight} size="1.5em" />}
                    size="md"
                    style={{ marginTop: "20px" }}
                    onClick={() =>
                      navigate(
                        "/professional/uitgelicht/uitgelichte-bronnen/over-de-lta-antistollingszorg/"
                      )
                    }
                  >
                    Verder lezen
                  </IconButton>
                </Col>
                <Col
                  xs={20}
                  style={{
                    backgroundColor: "#fff",
                    padding: "40px",
                    height: "100%",
                    border: "1px solid black",
                    borderRadius: "4px",
                  }}
                >
                  <h4 style={{ color: "#000" }}>Artikelen</h4>
                  <h2 style={{ color: "#000" }}>We zijn live!</h2>
                  <p
                    style={{

                      fontSize: "1.1em",
                      color: "#000",
                      lineHeight: "1.8",
                      width: "80%",
                      marginTop: "10px",
                    }}
                  >
                    Kennisplatform Antistolling brengt informatie en
                    initiatieven samen. Meer dan 1 miljoen mensen in Nederland
                    gebruiken enige vorm van antistollingsmiddelen, zoals
                    directe anticoagulantia, vitamine K antagonisten en
                    bloedplaatjesremmers. De zorg rondom deze medicatie is
                    complex en de toepassing ervan luistert zeer nauw. Daarnaast
                    zijn er veel professionals uit zowel ...
                  </p>
                  <IconButton
                    icon={<Icon as={BiArrowToRight} size="1.5em" />}
                    size="md"
                    style={{ marginTop: "20px" }}
                    appearance="primary"
                    onClick={() =>
                      navigate(
                        "/professional/uitgelicht/artikelen/we-zijn-live/"
                      )
                    }
                  >
                    Verder lezen
                  </IconButton>
                </Col>
              </Carousel>
            </Row>
          </Grid>
        </SectionWrapper>
      </div>
      <SourcesOverviewIndexPage />
      <SectionWrapper padded background>
        <Grid>
          <Row style={{ marginBottom: "0px" }}>
            <Col xs={24} md={16}>
              <h3
                style={{
                  marginBottom: "20px",
                  color: "#000",
                }}
              >
                Veelgestelde vragen
              </h3>
              <Panel
                header="Hoe kom ik in contact met een lokale trombose expert?"
                collapsible
                bordered
                style={{
                  marginTop: "40px",
                  backgroundColor: "white",
                  marginRight: "20px",
                }}
              >
                <p>
                  Klik op{" "}
                  <Link to="/professional/organisatie-vinden">
                    Organisatie vinden
                  </Link>{" "}
                  en klik vervolgens op de landkaart van Nederland op je lokale
                  ziekenhuis, regionaal expertise centrum of trombosedienst.
                </p>
              </Panel>
              <Panel
                header="Waar kan ik landelijke richtlijnen over trombose vinden?"
                collapsible
                bordered
                style={{
                  marginTop: "20px",
                  backgroundColor: "white",
                  marginRight: "20px",
                }}
              >
                <p>
                  Klik op{" "}
                  <Link to="/professional/richtlijnen">Richtlijnen</Link>. Hier
                  vind je de landelijke richtlijnen over trombose.{" "}
                </p>
              </Panel>
              <Panel
                header="Waar kan ik lokale protocollen over trombose vinden?"
                collapsible
                bordered
                style={{
                  marginTop: "20px",
                  backgroundColor: "white",
                  marginRight: "20px",
                }}
              >
                <p>
                  Klik op{" "}
                  <Link to="/professional/organisatie-vinden">
                    Organisatie vinden
                  </Link>{" "}
                  en klik vervolgens op de landkaart van Nederland op je lokale
                  ziekenhuis, regionaal expertise centrum of trombosedienst.
                  Hier vind je de lokale protocollen over trombose
                </p>
              </Panel>
              <Panel
                header="Hoe kom ik in contact met een lokale trombosedienst?"
                collapsible
                bordered
                style={{
                  marginTop: "20px",
                  backgroundColor: "white",
                  marginRight: "20px",
                }}
              >
                <p>
                  Klik op{" "}
                  <Link to="/professional/organisatie-vinden">
                    Organisatie vinden
                  </Link>{" "}
                  en klik vervolgens op de landkaart van Nederland op je lokale
                  trombosedienst. Indien je dit niet vindt kun je de lokale
                  trombosedienst vinden via{" "}
                  <a
                    href="https://www.fnt.nl"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    fnt.nl
                  </a>
                </p>
              </Panel>
              <Panel
                header="Hoe kan ik gegevens van mijn organisatie op deze website toevoegen of wijzigen?"
                collapsible
                bordered
                style={{
                  marginTop: "20px",
                  backgroundColor: "white",
                  marginRight: "20px",
                }}
              >
                <p>
                  Vooralsnog dienen vragen over het toevoegen of wijzigen van
                  gegevens ingediend te worden via de{" "}
                  <Link to="/contact">contactpagina</Link>.
                </p>
              </Panel>
            </Col>
            <Col xs={24} md={8}>
              <h3
                style={{
                  marginBottom: isMobile ? "0" : "20px",
                  marginTop: isMobile ? "20px" : "0",
                  color: "#000",
                }}
              >
                Handige links
              </h3>
              <List bordered style={{ marginTop: "40px" }}>
                <List.Item
                  style={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    marginBottom: "20px",
                  }}
                >
                  <p style={{ marginBottom: "10px" }}>
                    De Federatie van Nederlandse Trombosediensten (FNT) is de
                    branchevereniging van de Nederlandse trombosediensten.
                  </p>
                  <a
                    href="https://www.fnt.nl/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      as={BiLinkExternal}
                      size="1.25em"
                      style={{ marginRight: "10px", color: "#ee9a94" }}
                    />
                    fnt.nl
                  </a>
                </List.Item>

                <List.Item
                  style={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    marginBottom: "20px",
                  }}
                >
                  <p style={{ marginBottom: "10px" }}>
                    Bekijk alle informatie over antistolling op thuisarts.nl:
                    betrouwbare informatie over ziekte en gezondheid, gemaakt
                    door artsen.
                  </p>
                  <a
                    href="https://www.thuisarts.nl/zoeken?s=antistolling"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      as={BiLinkExternal}
                      size="1.25em"
                      style={{ marginRight: "10px", color: "#ee9a94" }}
                    />
                    thuisarts.nl
                  </a>
                </List.Item>

                <List.Item
                  style={{
                    border: "1px solid black",
                    borderRadius: "5px",
                    marginBottom: "20px",
                  }}
                >
                  <p style={{ marginBottom: "10px" }}>
                    Bekijk het thema antistollingszorg op Tijd voor Verbinding.
                  </p>
                  <a
                    href="https://www.programmatvv.nl/thema-pagina-s/antistollingszorg/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      as={BiLinkExternal}
                      size="1.25em"
                      style={{ marginRight: "10px", color: "#ee9a94" }}
                    />
                    Tijd voor Verbinding
                  </a>
                </List.Item>
              </List>
            </Col>
          </Row>
        </Grid>
      </SectionWrapper>
    </LayoutMain>
  );
};

export default IndexPage;
