import React from "react";
import { Grid, Row, Col } from "rsuite";
import ExternalSourcePanel from "../ui/externalSourcePanel";
import { useStaticQuery, graphql } from "gatsby";
import SectionWrapper from "./sectionWrapper";
import { StaticImage } from "gatsby-plugin-image";

export default function SourcesOverviewIndexPage() {
  const data = useStaticQuery(graphql`
    query {
      allBronnenCsv(limit: 3, sort: { order: ASC, fields: id }) {
        nodes {
          title
          source
          owner
          type
          description
        }
      }
    }
  `);
  return (
    <>
      <SectionWrapper padded>
        <div className="header-gradient">
          <StaticImage
            src="../../images/internal/background2.jpeg"
            alt="Logo Trombosestichting Nederland"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              zIndex: "-2",
            }}
          />
          <Grid>
            <Row>
              <Col xs={24}>
                <h3 style={{ marginBottom: "40px", color: "#fff" }}>
                  Recent toegevoegde lokale protocollen
                </h3>
              </Col>
              {data.allBronnenCsv.nodes.map((node) => (
                <Col xs={24} md={8} key={node.title}>
                  <ExternalSourcePanel
                    title={node.title}
                    description={node.description}
                    source={node.source}
                    type={node.type}
                    category={node.category}
                    owner={node.owner}
                  />
                </Col>
              ))}
            </Row>
          </Grid>
        </div>
      </SectionWrapper>
    </>
  );
}
